import { apiGateway, apiSchemas } from "@smart/bot-resources-basic";
import { ApiResults, useApi } from "@smart/itops-hooks-dom";

export const botApi = useApi(
  apiGateway.prefix,
  apiGateway.lambdaKeys,
  apiGateway.lambdas,
  apiSchemas,
);

export type BotApiResults = ApiResults<typeof botApi>;

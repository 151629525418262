import { z } from "zod";

import { jsonParse } from "@smart/itops-utils-basic";

export const verificationSchema = z.object({
  token: z.string(),
  challenge: z.string(),
  type: z.literal("url_verification"),
});

export type Verification = z.infer<typeof verificationSchema>;

export const payloadSchema = z.object({
  payload: z.preprocess(
    (val) => (typeof val === "string" ? jsonParse(val, "payload") : val),
    z.object({ type: z.string() }).passthrough(),
  ),
});

export type Payload = z.infer<typeof payloadSchema>;

export const eventCallbackSchema = z
  .object({
    type: z.literal("event_callback"),
    event: z
      .object({
        type: z.string(),
        user: z.string(),
        event_ts: z.string(),
      })
      .passthrough(),
    authorizations: z.array(
      z.object({
        team_id: z.string(),
        user_id: z.string(),
        is_bot: z.boolean(),
      }),
    ),
  })
  .passthrough();

export type EventCallback = z.infer<typeof eventCallbackSchema>;

import { buildCloudfrontDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { zone } from "./zone";

export const cdn = buildCloudfrontDef({
  zone,
  namespace: buildNamespace({ system, entity: "bot" }),
  subdomain: undefined,
  fallbackRoute: "index.html",
});

import { buildZoneDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";

export const zone = buildZoneDef({
  namespace: buildNamespace({ system, entity: "matter-intake" }),
  domains: {
    local: {
      aus: "bot.smartsb.dev",
      nova: "bot.smartsb.dev",
      lon: "bot.smartsb.dev",
    },
    dev: {
      aus: "",
      nova: "",
      lon: "",
    },
    staging: {
      aus: "link-sb.com",
      nova: "",
      lon: "",
    },
    live: {
      aus: "",
      nova: "",
      lon: "",
    },
  },
});

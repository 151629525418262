import { AWSInfo, InfraInfo, InfoKeys } from "@smart/itops-utils-basic";

export const defaultEnvKeys = [
  "AWSAccountId",
  "AWSRegion",
  "Env",
  "Region",
] as const;

export type DefaultEnvKey = (typeof defaultEnvKeys)[number];
export type DefaultEnvKV = { [key in DefaultEnvKey]: string };

export const envKeys = [
  "BitbucketUsername",
  "BitbucketPassword",
  "JiraDomain",
  "JiraToken",
  "JiraUsername",
  "OctopusHost",
  "OctopusKey",
  "SlackBotToken",
  "SlackSigningSecret",
  "SlackClientId",
  "SlackClientSecret",
  "TeamCityHost",
  "TeamCityUsername",
  "TeamCityPassword",
  "StagingAccountId",
  "LiveAccountId",
  "FamilyProApiHost",
  "FamilyProApiUrl",
  "FamilyProApiToken",
  "SmokeballApiHost",
  "SmokeballAiApiHost",
  "SmokeballApiKey",
  "SmokeballTokenHost",
  "SmokeballTokenClientId",
  "SmokeballTokenClientSecret",
  "SmokeballApiHostIntegrationTest",
  "SmokeballApiKeyIntegrationTest",
  "SmokeballTokenHostIntegrationTest",
  "SmokeballTokenClientIdIntegrationTest",
  "SmokeballTokenClientSecretIntegrationTest",
  "SmokeballDefaultAccountIdIntegrationTest",
  "ChromaticItopsUi",
] as const;

export type EnvKey = (typeof envKeys)[number];
export type EnvKV = { [key in EnvKey]: string };

export const awsInfoKeys: InfoKeys<AWSInfo, DefaultEnvKey> = {
  accountId: "AWSAccountId",
  region: "AWSRegion",
};

export const infraInfoKeys: InfoKeys<InfraInfo, DefaultEnvKey> = {
  env: "Env",
  region: "Region",
};

export const bitbucketKeys: EnvKey[] = [
  "BitbucketUsername",
  "BitbucketPassword",
];

export const jiraKeys: EnvKey[] = ["JiraDomain", "JiraToken", "JiraUsername"];

export const octopusKeys: EnvKey[] = ["OctopusHost", "OctopusKey"];

export const slackKeys: EnvKey[] = [
  "SlackBotToken",
  "SlackSigningSecret",
  "SlackClientId",
  "SlackClientSecret",
];

export const teamCityKeys: EnvKey[] = [
  "TeamCityHost",
  "TeamCityUsername",
  "TeamCityPassword",
];

export const accountIdKeys: EnvKey[] = ["StagingAccountId", "LiveAccountId"];

export const smokeballApiKeys = [
  "SmokeballApiHost",
  "SmokeballApiKey",
  "SmokeballTokenHost",
  "SmokeballTokenClientId",
  "SmokeballTokenClientSecret",
] as const;

export const smokeballApiIntegrationTestKeys = [
  "SmokeballApiHostIntegrationTest",
  "SmokeballApiKeyIntegrationTest",
  "SmokeballTokenHostIntegrationTest",
  "SmokeballTokenClientIdIntegrationTest",
  "SmokeballTokenClientSecretIntegrationTest",
  "SmokeballDefaultAccountIdIntegrationTest",
] as const;

export const smokeballApiIntegrationTestKeyMap = {
  SmokeballApiHost: "SmokeballApiHostIntegrationTest",
  SmokeballApiKey: "SmokeballApiKeyIntegrationTest",
  SmokeballTokenHost: "SmokeballTokenHostIntegrationTest",
  SmokeballTokenClientId: "SmokeballTokenClientIdIntegrationTest",
  SmokeballTokenClientSecret: "SmokeballTokenClientSecretIntegrationTest",
} as const;

import { namespacedList } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { ChangeDef } from "./change";
import { DocumentationDef } from "./docs";

export const buckets = namespacedList({
  Change: ChangeDef,
  Documentation: DocumentationDef,
});

export const bucketKeys = keysOf(buckets);

export type Buckets = typeof buckets;

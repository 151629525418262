import { buildAssetBucketDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "../base";

const ChangeNS = buildNamespace({ system, entity: "Change" } as const);

export const ChangeDef = buildAssetBucketDef({
  cdn: false,
  prefix: undefined,
  namespace: ChangeNS,
});

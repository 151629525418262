import { SNSSQSTypes } from "@smart/itops-constructs-basic";
import { namespacedList } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { EventDef } from "./event";

export const transports = namespacedList({
  Event: EventDef,
});

export const transportKeys = keysOf(transports);

export type Transports = typeof transports;

export type TransportKey = keyof Transports;

export type TransportTypes = SNSSQSTypes<Transports>;

export type TEvent = TransportTypes["Event"];

import { buildPermissionsDef } from "@smart/itops-constructs-basic";

import { apiGateway } from "./api-gateway";
import { bucketKeys } from "./buckets";
import { snsSqsLambdas } from "./sns-sqs";
import { tableKeys } from "./tables";
import { transportKeys } from "./transports";

export const permissions = buildPermissionsDef(
  ["authorizer", ...apiGateway.lambdaKeys, ...snsSqsLambdas.lambdaKeys],
  tableKeys,
  transportKeys,
  bucketKeys,
  [],
  {
    authorizer: {
      dynamo: { default: null, User: "READ" },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    code: {
      dynamo: { default: null },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    gql: {
      dynamo: { default: "READ" },
      sns: { default: "PUBLISH" },
      s3: { default: "READ" },
      additionalResources: { default: null },
    },
    me: {
      dynamo: { default: null },
      sns: { default: null },
      s3: { default: null },
      additionalResources: { default: null },
    },
    octopus: {
      dynamo: { default: null },
      sns: { default: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    slack: {
      dynamo: { default: null },
      sns: { default: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    teamcity: {
      dynamo: { default: null },
      sns: { default: "PUBLISH" },
      s3: { default: null },
      additionalResources: { default: null },
    },
    Event: {
      dynamo: { default: "WRITE" },
      sns: { default: "PUBLISH" },
      s3: { default: null, Change: "READ" },
      additionalResources: { default: null },
    },
  },
);

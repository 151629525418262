import { buildAssetBucketDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "../base";

const DocumentationNS = buildNamespace({
  system,
  entity: "Documentation",
} as const);

export const DocumentationDef = buildAssetBucketDef({
  cdn: false,
  prefix: undefined,
  namespace: DocumentationNS,
});

import { z } from "zod";

import { buildTableDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

import { system } from "../base";

export const UserNS = buildNamespace({
  system,
  entity: "User",
} as const);

export const UserSchema = z.object({
  source: z.string(),
  userId: z.string(),
  authorized: z.boolean(),
  email: z.string().optional(),
});

const userGuard = wrapSchema(UserSchema);

export const UserDef = buildTableDef(
  {
    namespace: UserNS,
    guard: userGuard,
    keyFields: ["source", "userId"],
    keySchema: { source: "string", userId: "string" },
  } as const,
  {
    byEmail: {
      indexName: "byEmail",
      keyFields: ["email"],
      keySchema: { email: "string" },
    },
  } as const,
);

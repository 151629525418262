import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Loading } from "@smart/itops-components-dom";
import { Status } from "@smart/itops-hooks-dom";

import { botApi, BotApiResults } from "./api";

export const TOKEN_STORAGE_KEY = "smart-bot-token" as const;
export const REDIRECT_STORAGE_KEY = "smart-bot-previous" as const;

export type UserContextValue = {
  token: string;
  status: Status;
  user?: BotApiResults["me"];
  signOut: () => void;
};

export const UserContext = createContext<UserContextValue>({
  token: "",
  status: "initial",
  signOut: () => {},
});

export const setupUser = (): UserContextValue | undefined => {
  const [token, setToken] = useState<string>();
  const me = botApi.me();

  useEffect(() => {
    const stored = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (stored) {
      setToken(stored);
      me.post({
        body: undefined,
        token: stored,
      }).catch(console.error);
    } else {
      localStorage.setItem(REDIRECT_STORAGE_KEY, window.location.pathname);
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    setToken(undefined);
  }, []);

  return token
    ? {
        token,
        signOut,
        status: me.status,
        user: me.result,
      }
    : undefined;
};

export const useUser = () => useContext(UserContext);

export type UserProviderProps = {
  children: ReactNode;
  signIn: JSX.Element;
};

export const UserProvider = ({ children, signIn }: UserProviderProps) => {
  const value = setupUser();

  if (value && value.user) {
    return (
      <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
  }

  if (value) {
    return <Loading />;
  }

  return signIn;
};

import { z } from "zod";

import { buildTableDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

import { system } from "../base";

export const ChannelNS = buildNamespace({
  system,
  entity: "Channel",
} as const);

export const ChannelSchema = z.object({
  channelId: z.string(),
  purpose: z.string(),
});

const channelGuard = wrapSchema(ChannelSchema);

export const ChannelDef = buildTableDef(
  {
    namespace: ChannelNS,
    guard: channelGuard,
    keyFields: ["purpose"],
    keySchema: { purpose: "string" },
  } as const,
  {} as const,
);

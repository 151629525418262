import { z } from "zod";

import { buildSNSSQSDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { wrapSchema } from "@smart/itops-utils-basic";

import { system } from "../base";

const EventNS = buildNamespace({ system, entity: "Event" } as const);

export const EventSchema = z.object({
  context: z.object({
    source: z.enum([
      "Bitbucket",
      "Octopus",
      "Slack",
      "TeamCity",
      "Gql",
      "Codebuild",
    ]),
    userId: z.string(),
    channelId: z.string(),
    messageTs: z.string().optional(),
    ts: z.string(),
  }),
  action: z.object({
    key: z.string(),
    args: z.object({}).passthrough(),
  }),
});

const eventGuard = wrapSchema(EventSchema);

export const EventDef = buildSNSSQSDef({
  namespace: EventNS,
  guard: eventGuard,
  sqsOnly: false,
  groupKey: undefined,
  idKey: undefined,
  maxAttempts: 2,
  visibilityTimeout: 300,
});

import { z } from "zod";

import { buildApiGatewaySchemas } from "@smart/itops-constructs-basic";
import { undefinedOrEmptyObject } from "@smart/itops-utils-basic";

import { apiGateway } from "./api-gateway";
import {
  eventCallbackSchema,
  payloadSchema,
  verificationSchema,
} from "./shared-schemas";

export const codeLambdaSchema = {
  input: z
    .object({
      code: z.string().optional(),
    })
    .or(z.undefined()),
  output: z.literal(""),
};

export const gqlLambdaSchema = {
  input: z.any(),
  output: z.any(),
};

export const slackLambdaSchema = {
  input: verificationSchema.or(eventCallbackSchema).or(payloadSchema),
  output: z.object({
    challenge: z.string().optional(),
  }),
};

export const notifyLambdaSchema = {
  input: z.object({
    id: z.string(),
    action: z.string(),
  }),
  output: z.object({
    id: z.string(),
  }),
};

export const meLambdaSchema = {
  input: undefinedOrEmptyObject,
  output: z.object({
    userUri: z.string(),
    teamUri: z.string().optional(),
    source: z.string(),
    email: z.string(),

    firstName: z.string(),
    lastName: z.string(),
    initials: z.string().optional(),
    picture: z.string().optional(),
  }),
};

export const apiSchemas = buildApiGatewaySchemas(apiGateway, {
  code: codeLambdaSchema,
  gql: gqlLambdaSchema,
  me: meLambdaSchema,
  octopus: notifyLambdaSchema,
  slack: slackLambdaSchema,
  teamcity: notifyLambdaSchema,
});

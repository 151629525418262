import { buildAppBucketDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";

import { system } from "./base";
import { cdn } from "./cloudfront";

export const appBucket = buildAppBucketDef({
  cdn,
  namespace: buildNamespace({ system, entity: "dev" }),
  prefix: undefined,
  pkg: "@smart/bot-app-web",
  port: 9140,
});

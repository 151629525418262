import { TableTypes } from "@smart/itops-constructs-basic";
import { namespacedList } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { ChannelDef } from "./channel";
import { UserDef } from "./user";

export const tables = namespacedList({
  Channel: ChannelDef,
  User: UserDef,
});

export type Tables = typeof tables;

export const tableKeys = keysOf(tables);

export type EntityTypes = TableTypes<Tables>;

export type TUser = EntityTypes["User"];

import {
  accountIdKeys,
  bitbucketKeys,
  envKeys,
  jiraKeys,
  octopusKeys,
  slackKeys,
  smokeballApiIntegrationTestKeys,
  teamCityKeys,
} from "@smart/bot-env-types-basic";
import { buildSNSSQSHandlersDef } from "@smart/itops-constructs-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { transports } from "./transports";

const lambdaDefs = {
  Event: {
    pkg: "@smart/bot-event-processor-lambda",
    deployable: "Event",
    env: [
      ...jiraKeys,
      ...octopusKeys,
      ...slackKeys,
      ...teamCityKeys,
      ...accountIdKeys,
      ...bitbucketKeys,
      ...smokeballApiIntegrationTestKeys,
    ],
  },
} as const;

export const snsSqsLambdas = buildSNSSQSHandlersDef({
  envKeys,
  lambdaDefs,
  lambdaKeys: keysOf(lambdaDefs),
  queueDefs: transports,
});

import {
  accountIdKeys,
  envKeys,
  octopusKeys,
  slackKeys,
  teamCityKeys,
} from "@smart/bot-env-types-basic";
import { buildApiGatewayDef } from "@smart/itops-constructs-basic";
import { buildNamespace } from "@smart/itops-types-basic";
import { keysOf } from "@smart/itops-utils-basic";

import { system } from "./base";
import { cdn } from "./cloudfront";

const lambdaDefs = {
  code: {
    pkg: "@smart/bot-code-lambda",
    deployable: "code",
    method: "GET",
    authenticated: false,
    env: [...slackKeys],
  },
  gql: {
    pkg: "@smart/bot-gql-lambda",
    deployable: "gql",
    method: "POST",
    authenticated: true,
    env: [...accountIdKeys, ...slackKeys],
  },
  me: {
    pkg: "@smart/bot-me-lambda",
    deployable: "me",
    method: "POST",
    authenticated: true,
    env: [],
  },
  octopus: {
    pkg: "@smart/bot-octopus-lambda",
    deployable: "octopus",
    method: "POST",
    authenticated: false,
    env: [...octopusKeys],
  },
  slack: {
    pkg: "@smart/bot-slack-lambda",
    deployable: "slack",
    method: "POST",
    authenticated: false,
    env: [...slackKeys],
  },
  teamcity: {
    pkg: "@smart/bot-teamcity-lambda",
    deployable: "teamcity",
    method: "POST",
    authenticated: false,
    env: [...teamCityKeys],
  },
} as const;

export const apiGateway = buildApiGatewayDef({
  envKeys,
  cdn,
  namespace: buildNamespace({ system, entity: "bot" }),
  prefix: "api",
  authorized: true,
  authorizer: {
    pkg: "@smart/bot-authorizer-lambda",
    deployable: "authorizer",
    env: [...slackKeys],
  },
  lambdaKeys: keysOf(lambdaDefs),
  lambdas: lambdaDefs,
  port: 9141,
});
